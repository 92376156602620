import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import FeaturedCarousel from "@components/FeaturedCarousel"
import AppButtonsSection from "@components/AppButtonsSection"
import ReviewTestimonials from "@components/ReviewTestimonials"
import LegalTeam from "@components/LegalTeam"
import HeroBackground from "@images/hero_background.png"
import FreeAppProbate from "@images/freeapp-probate.png";
import FreeAppLettersOfAdmin from "@images/freeapp-letters-of-admin.png";
import FreeAppAdminHelp from "@images/freeapp-admin-help.png";
import FreeAppFlag from "@images/freeapp-flag.png";
import FreeAppIconRoute from "@images/freeapp-icon-route.png";
import FreeAppIconRealEstate from "@images/freeapp-icon-realestate.png";
import FreeAppIconTasks from "@images/freeapp-icon-tasks.png";
import Iphone from "@images/iphone_vertical.png"
import Tick from "@images/tick_blue.svg"
import AppStoreImg from "@icons/appstore.png"
import PlayStoreImg from "@icons/playstore.png"

import "./styles.scss"

const DownloadApp = ({ data, path }) => {
  // const { topSection } = data.markdownRemark.frontmatter
  return (
    <Layout hasSubNav={true} navId="lostALovedOne" loadMap={false}>
      <SEO
        path={path}
        title="Download App | Learn Everything About Estates Plus"
        description="ESTATES PLUS is the most affordable way to obtain the legal documents necessary to finalise the financial affairs of a loved one who has passed away.We help prepare PROBATE and LETTERS OF ADMINISTRATION saving you up to80%* in legal fees."
        image="/img/Nicholas.png"
      />

      <section className="">
        <img
          src={HeroBackground}
          className="banner"
          style={{ width: "100%", zIndex: -1, position: "absolute" }}
          alt="bg"
        />
        <div className="container section-gap">
          <section className="">
            {/* <section className="container"> */}
            <div className="row center m-0">
              <div className="col-lg-7">
                <h1 className="font-bold text-left font-medium font-light title-text">
                  The
                  <span className="bg-accent d-inline-block text-white px-1 pb-2 m-2">
                    Free App
                  </span>
                  For
                </h1>
                <h1 className="font-bold text-left title-text">
                  Finalising A
                  <span className="bg-accent d-inline-block text-white px-1 pb-2 my-2 mx-lg-2">
                    Deceased Estate
                  </span>
                </h1>
                <p className="font-semibold m-0">
                  The Free, Lawyer Prepared Guide On What
                </p>
                <p className="font-semibold">To Do When Some Passes Away</p>
                <div className="flex-column">
                  <a href="https://apps.apple.com/us/app/estates-plus/id1567370486" className="mx-1" target="_blank" rel="noreferrer">
                    <img
                      src={AppStoreImg}
                      alt="appstore"
                      className="box-app-play-store"
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.estate.native" className="mx-1" target="_blank" rel="noreferrer">
                    <img
                      src={PlayStoreImg}
                      alt="playstore"
                      className="box-app-play-store"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-5 p-0">
                <iframe
                  title="Estate Info"
                  className="video-iframe"
                  src="https://www.youtube.com/embed/PlrryU1HRjA"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            {/* </section> */}
          </section>
        </div>
      </section>

      <section className="bg-white">
        <FeaturedCarousel />
      </section>
      <section className="bg-light px-lg-5 py-5">
        <div className="container bg-light">
          <div
            className="col-12 custom-shadow bg-white custom-rounded-lg">
            <div className="row px-4 py-5 align-items-center">
              <div className="col-12 col-lg-5 pl-xl-5 mb-5 mb-xl-0 d-flex justify-content-center justify-content-end">
                <img
                  src={Iphone}
                  className="free-app-img"
                  alt="Free App Download"
                />
              </div>
              <div
                className="col-12 col-lg-7 px-0 pr-lg-8 px-xl-5"
              >
                <h2 className="mb-4 w-xl-75 font-black text-center text-lg-left">
                  It Can Be Overwhelming Finding Out What To Do Next..
                </h2>
                <p className="mb-4 text-center text-lg-left">
                  Researching online is difficult. All the information is all over the
                  place. It's hard to find information you can trust.
                </p>
                <p className="mb-4 text-center text-lg-left">
                  We created this app because we know how difficult it is when
                  someone dies. We brought all the information in one place to
                  make it easier for you.
                </p>
                <p className="mb-4 text-center text-lg-left">
                  We explain legal terminology in simple, easy to understand
                  language.
                </p>
                <p className="mb-4 text-center text-lg-left">
                  You can also save tasks, notes, and store information about the
                  estate.
                </p>
                <p className="mb-4 text-center text-lg-left">
                  The app is available Australia-wide for free. Simply click to
                  download.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AppButtonsSection />
      <section className="bg-light px-lg-5 py-5">
        <div className="container bg-light">
          <div
            className="col-12 custom-shadow bg-white custom-rounded-lg">
            <div className="row flex-row-reverse px-4 py-5 align-items-center">
              <div className="col-12 col-lg-5 px-xl-5 mb-5 mb-lg-0 d-flex justify-content-center justify-content-end">
                <img
                  src={FreeAppProbate}
                  className="free-app-img"
                  alt="Free App Download"
                />
              </div>
              <div
                className="col-12 col-lg-7 px-0 pl-lg-6 pl-xl-5"
              >
                <h2 className="mb-4 w-xl-75 font-black text-center text-lg-left">
                  What Is Probate?
                </h2>
                <p className="mb-4 text-center text-lg-left">
                  <strong>Probate</strong> applies if your loved one left a Will. The Will nominates
                  someone (usually a family member) as an <strong>executor</strong> to the Estate. This person must <Link to="/lost-a-loved-one/probate/">apply for a Grant of Probate</Link> with the court to gain access to the information and assets of the estate.
                </p>
                <p className="mb-4 text-center text-lg-left">
                  Learn more about the Probate process and much more in our free app, which includes an easy-to-read glossary of legal terms.
                </p>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <a href="#app-buttons-anchor" className="btn btn-primary">
                    Find Out More In Our Free App
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light px-lg-5 py-5">
        <div className="container bg-light">
          <div
            className="col-12 custom-shadow bg-white custom-rounded-lg">
            <div className="row flex-row-reverse px-4 py-5 align-items-lg-center">
              <div className="col-12 col-lg-7 px-xl-5 mb-5 mb-lg-0 d-flex justify-content-center justify-content-end">
                <img
                  src={FreeAppAdminHelp}
                  className="free-app-img multiple"
                  alt="Free App Admin Help"
                />
              </div>
              <div
                className="col-12 col-lg-5 px-0 pl-lg-6 pl-xl-5"
              >
                <img
                  src={FreeAppFlag}
                  className="d-none d-lg-block w-25 mb-4"
                  alt="Free App Flag"
                />
                <div className="pl-0 pl-xl-4">
                  <h2 className="mb-4 w-xl-75 font-black text-center text-lg-left">
                    Estate Administration Help
                  </h2>
                  <p className="mb-4 text-center text-lg-left">
                    We give you all the legal processes and terms in one place, so that you can manage the estate administration process in an easy and efficient way.
                  </p>
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-start mb-4">
                    <img
                      src={FreeAppIconRoute}
                      className="free-app-icon mr-4"
                      alt="FreeApp Benefits Route"
                    />
                    <h3 className="mb-0 font-bold">Understand the Steps</h3>
                  </div>
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-start mb-4">
                    <img
                      src={FreeAppIconRealEstate}
                      className="free-app-icon mr-4"
                      alt="FreeApp Benefits Real Estate"
                    />
                    <h3 className="mb-0 font-bold">Save Assets & Debts</h3>
                  </div>
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                    <img
                      src={FreeAppIconTasks}
                      className="free-app-icon mr-4"
                      alt="FreeApp Benefits Tasks"
                    />
                    <h3 className="mb-0 font-bold">Save Notes & Tasks</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="app-buttons-anchor" className="pt-8" style={{ marginTop: "-6rem" }}></section>
      <AppButtonsSection />
      <section className="bg-light px-lg-5 py-5">
        <div className="container bg-light">
          <div
            className="col-12 custom-shadow bg-white custom-rounded-lg">
            <div className="row px-4 py-5 align-items-center">
              <div className="col-12 col-lg-5 pl-xl-5 mb-5 mb-xl-0 d-flex justify-content-center justify-content-end">
                <img
                  src={FreeAppLettersOfAdmin}
                  className="free-app-img"
                  alt="Free App Letters Of Administration"
                />
              </div>
              <div
                className="col-12 col-lg-7 px-0 pr-lg-8 px-xl-5"
              >
                <h2 className="mb-4 w-xl-75 font-black text-center text-lg-left">
                  What About Letters of Administration?
                </h2>
                <p className="mb-4 text-center text-lg-left">
                  <strong>Letters of Administration</strong> is the opposite to Probate and applies if your loved did not leave a Will. An administrator is similar to an executor and is appointed by the <strong>court</strong> to manage the information and assets of the estate. The administrator applies for a <strong>grant</strong> of Letters of Administration.
                </p>
                <p className="mb-4 text-center text-lg-left">
                  Find out about the letters of administration process and how you can get  it in our app.
                </p>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <a href="#app-buttons-anchor" className="btn btn-primary">
                    Find Out More In Our Free App
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container section-gap">
          <div className="row d-flex align-items-center justify-content-center m-0">
            <div class="col-12 col-xl-9 bg-white shadow-sm overflow-hidden py-5 br">
              <h2 className="mb-4 w-100 font-bold text-center">
                How It Works
              </h2>

              <div className="w-100 d-flex justify-content-center align-items-center my-3 px-3 pr-xl-5">
                <div className="d-none d-md-block ml-0 mr-5 mx-lg-5">
                  <img src={Tick} alt="tick" className="check" />
                </div>
                <p className="text-center text-lg-left">
                  You recieve step-by-step explanations on topics and obligations
                  like organising an affordable funeral , notifying
                  organisations, getting a death certificate and
                  securing the estate.
                </p>
              </div>

              <div className="w-100 d-flex justify-content-center align-items-center my-3 px-3 pr-xl-5">
                <div className="d-none d-md-block ml-0 mr-5 mx-lg-5">
                  <img src={Tick} alt="tick" className="check" />
                </div>
                <p className="text-center text-lg-left">
                  Save and store important financial information relating to the
                  estate. Executors, and those dealing with the estate, can keep this data in a central
                  location for future use when distributing estate assets to
                  beneficiaries.
                </p>
              </div>

              <div className="w-100 d-flex justify-content-center align-items-center mt-3 mb-5 px-3 pr-xl-5">
                <div className="d-none d-md-block ml-0 mr-5 mx-lg-5">
                  <img src={Tick} alt="tick" className="check" />
                </div>
                <p className="text-center text-lg-left">
                  Track your progress using our task manager and complete
                  recommended tasks based on your personal situation. Knowing the
                  outstanding tasks you need to complete is a great organising
                  tool to help reduce confusion.
                </p>
              </div>
              <div className="w-100 d-flex justify-content-center align-items-center my-3">
                <a href="https://apps.apple.com/us/app/estates-plus/id1567370486" className="mx-1" target="_blank" rel="noreferrer">
                  <img
                    src={AppStoreImg}
                    alt="appstore"
                    className="box-app-play-store"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.estate.native" className="mx-1" target="_blank" rel="noreferrer">
                  <img
                    src={PlayStoreImg}
                    alt="playstore"
                    className="box-app-play-store"
                  />
                </a>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="bg-light">
        <ReviewTestimonials />
      </section>
      <section className="bg-white">
        <LegalTeam />
      </section>
    </Layout>
  )
}

export default DownloadApp

export const pageQuery = graphql`
  query {
    markdownRemark(
      frontmatter: { templateKey: { eq: "lost-a-loved-one/download-app" } }
    ) {
      frontmatter {
        topSection {
          description
          teamImageAlt
          teamImageMobileAlt
          teamImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          teamImageMobile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        lawyersSection {
          list {
            name
            title
            imageAlt
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
