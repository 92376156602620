import React from "react";

import AppStoreImg from "@icons/appstore.png"
import PlayStoreImg from "@icons/playstore.png"

const AppButtonsSection = () => {
  return (
    <section style={{ background: "#e6f8ff" }}>
      <div className="container">
        <div
          className="d-flex justify-content-center align-items-center p-5">
          <a href="https://apps.apple.com/us/app/estates-plus/id1567370486" className="mx-1" target="_blank" rel="noreferrer">

            <img src={AppStoreImg} alt="appstore" className="box-app-play-store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.estate.native" className="mx-1" target="_blank" rel="noreferrer">

            <img
              src={PlayStoreImg}
              alt="playstore"
              className="box-app-play-store"
            />
          </a>
        </div>
      </div>
    </section>
  )
}

export default AppButtonsSection;